<template>
  <div class="thank-you-message">
    <div v-html="thankyouMessageTitle"></div>

    <div class="divider"></div>

    <div v-html="thankyouMessageText"></div>

    <custom-button variant="primary" :disabled="true">
      Aceptar
    </custom-button>
  </div>
</template>

<script>
import { mapState } from "vuex";

import CustomButton from "../../components/CustomButton.vue";

export default {
  name: "ThankYouMessage",

  components: { CustomButton },

  computed: {
    ...mapState({
      thankyouMessageTitle: state => state.survey.goodbyeMessageTitle,
      thankyouMessageText: state => state.survey.goodbyeMessageText,
      finishedSurvey: state => state.userProfile.finishedSurvey
    })
  }
};
</script>

<style scoped>
.thank-you-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.divider {
  width: 20px;
  height: 2px;
  margin-bottom: var(--lengthMd1);
  background-color: var(--mainColor1);
}

.btn {
  margin: var(--lengthLg1) auto 0;
}

.logo {
  margin: 0 auto var(--lengthLg1);
}
</style>
